import React from 'react';

const NotAllow = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="43.415"
    height="43.406"
    viewBox="0 0 43.415 43.406"
  >
    <defs>
      <clipPath>
        <rect width="43.415" height="43.406" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M21.7,43.406A21.7,21.7,0,0,1,21.681,0h.052a21.7,21.7,0,0,1-.016,43.406ZM9.776,35.921A18.587,18.587,0,0,0,40.327,21.655a18.138,18.138,0,0,0-4.4-11.877ZM21.669,3.1A18.548,18.548,0,0,0,7.482,33.638L33.628,7.5A18.268,18.268,0,0,0,21.669,3.1"
        transform="translate(0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default NotAllow;
